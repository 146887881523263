import React, { ForwardedRef, forwardRef, useState } from "react";
import { useForm } from "react-hook-form";
import { EnvelopeIcon } from "@heroicons/react/24/outline";
import { StaticImage } from "gatsby-plugin-image";
import Content from "../components/Content";
import Header from "../components/Header";
import Footer from "../components/Footer";
import classNames from "classnames";
import { navigate } from "gatsby";
import { SEO } from "../components/SEO";

interface IInput {
  label?: string;
  sublabel?: string;
  name: string;
  type?: string;
  autoComplete?: string;
  error?: any;
}

const Input = forwardRef(
  (
    { label, sublabel, name, error, ...props }: IInput,
    ref: ForwardedRef<any>
  ) => {
    return (
      <>
        <div className="flex justify-between">
          <label
            htmlFor={name}
            className="block text-sm font-medium text-gray-700"
          >
            {label}
          </label>
          {sublabel && (
            <span id="phone-description" className="text-sm text-gray-500">
              {sublabel}
            </span>
          )}
        </div>
        <div className="mt-1">
          <input
            ref={ref}
            id={name}
            name={name}
            {...props}
            className={classNames(
              "block w-full rounded-md shadow-sm sm:text-sm",
              {
                "border-red-400 text-red-900 focus:border-red-500 focus:ring-red-500":
                  !!error,
                "border-gray-300 focus:border-primary-500 focus:ring-primary-500":
                  !error,
              }
            )}
          />
          {error && <div className="text-xs text-red-900">{error.message}</div>}
        </div>
      </>
    );
  }
);
Input.defaultProps = {
  type: "text",
};

const Radio = forwardRef(
  ({ label, name, error, ...props }: IInput, ref: ForwardedRef<any>) => {
    const id = name + label?.replace(/[^a-zA-Z0-9]/g, "-");
    return (
      <div className="flex items-center">
        <input
          id={id}
          name={name}
          defaultValue={label}
          {...props}
          ref={ref}
          className="h-4 w-4 border-gray-300 text-primary-600 focus:ring-primary-500"
        />
        <label htmlFor={id} className="ml-3">
          <span className="block text-sm text-gray-700">{label}</span>
        </label>
      </div>
    );
  }
);
Radio.defaultProps = {
  type: "radio",
};

interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  description: string;
  budget: string;
  hearAbout: string;
}

function ContactPage() {
  var [error, setError] = useState<string>("");

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>();

  const onSubmit = (data: FormData) => {
    setError("");

    fetch("https://formsubmit.co/ajax/53e4cac1a35d01b4bd8b76bdf267bdbd", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success === "true") {
          navigate("/thanks");
        } else {
          setError(data.message);
        }
      })
      .catch((error) => setError(error.message));
  };

  return (
    <>
      <Header />
      <div className="bg-gray-50 py-16 sm:py-24">
        <div className="mx-auto max-w-md pl-4 pr-8 sm:max-w-lg sm:px-6 lg:max-w-7xl lg:px-8">
          <h1 className="text-center font-serif text-4xl font-extrabold leading-10 tracking-tight text-gray-900 sm:text-5xl sm:leading-none lg:text-6xl">
            Get in touch
          </h1>
          <p className="mx-auto mt-6 max-w-3xl text-center text-xl leading-normal text-gray-500">
            We'd love to hear from you and help you get your next big project
            started.
          </p>
        </div>
      </div>
      <Content light>
        <div className="lg:absolute lg:inset-0">
          <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
            <StaticImage
              className="h-56 w-full object-cover lg:absolute lg:h-full"
              src="../images/hero/contact.jpg"
              alt="Interior Design"
            />
          </div>
        </div>
        <div className="relative py-16 px-4 sm:py-20 sm:px-6 lg:mx-auto lg:grid lg:max-w-7xl lg:grid-cols-2 lg:px-8">
          <div className="lg:pr-8">
            <div className="mx-auto max-w-md sm:max-w-lg lg:mx-0">
              <h2 className="font-serif text-3xl font-extrabold tracking-tight sm:text-4xl">
                Let's work together
              </h2>
              <p className="mt-4 text-lg text-gray-500 sm:mt-3">
                We are always excited for new projects and determined to help
                you create a home or space that serves you best.
              </p>
              <div className="my-4 flex">
                <div className="flex-shrink-0">
                  <EnvelopeIcon
                    className="h-6 w-6 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3 text-base text-gray-500">
                  <p>
                    <a
                      href="mailto:hello@helincompany.com"
                      className="underline"
                    >
                      hello@helincompany.com
                    </a>
                  </p>
                </div>
              </div>
              <p className="mt-4 text-lg text-gray-500 sm:mt-3">
                Feel free to email us, or fill out our form below!
              </p>
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="mt-9 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
              >
                <div>
                  <Input
                    label="First Name"
                    {...register("firstName", {
                      required: "First name is required",
                    })}
                    error={errors.firstName}
                    autoComplete="given-name"
                  />
                </div>
                <div>
                  <Input
                    label="Last Name"
                    {...register("lastName", {
                      required: "Last name is required",
                    })}
                    error={errors.lastName}
                    autoComplete="family-name"
                  />
                </div>
                <div className="sm:col-span-2">
                  <Input
                    label="Email"
                    {...register("email", { required: "Email is required" })}
                    error={errors.email}
                    autoComplete="email"
                  />
                </div>
                <div className="sm:col-span-2">
                  <Input
                    label="Phone"
                    sublabel="Optional"
                    {...register("phone")}
                    error={errors.phone}
                    autoComplete="tel"
                  />
                </div>
                <div className="sm:col-span-2">
                  <div className="flex justify-between">
                    <label
                      htmlFor="how-can-we-help"
                      className="block text-sm font-medium text-gray-700"
                    >
                      How can we help you?
                    </label>
                    <span
                      id="how-can-we-help-description"
                      className="text-sm text-gray-500"
                    >
                      Max. 500 characters
                    </span>
                  </div>
                  <div className="mt-1">
                    <textarea
                      {...register("description", {
                        required: "We need to know how we can help you.",
                        maxLength: {
                          value: 500,
                          message:
                            "Your message is strangely long. Please shorten it up.",
                        },
                        minLength: {
                          value: 20,
                          message:
                            "That seems a bit too short to help us understand your needs.",
                        },
                      })}
                      id="description"
                      aria-describedby="how-can-we-help-description"
                      rows={4}
                      className={classNames(
                        "block w-full rounded-md border shadow-sm sm:text-sm",
                        {
                          "border-red-400 text-red-900 focus:border-red-500 focus:ring-red-500":
                            !!errors.description,
                          "border-gray-300 focus:border-primary-500 focus:ring-primary-500":
                            !errors.description,
                        }
                      )}
                    />
                    {errors.description && (
                      <div className="text-xs text-red-900">
                        {errors.description.message}
                      </div>
                    )}
                  </div>
                </div>
                <fieldset className="sm:col-span-2">
                  <legend
                    className={classNames(
                      "block text-sm font-medium text-gray-700",
                      { "text-red-700": !!errors.budget }
                    )}
                  >
                    Expected budget
                    {errors.budget && (
                      <div className="text-xs">
                        Please help us determine your budget
                      </div>
                    )}
                  </legend>
                  <div className="mt-4 grid grid-cols-1 gap-y-4">
                    <Radio
                      label="Less than $25K"
                      {...register("budget", { required: true })}
                    />
                    <Radio
                      label="$25K - $100K"
                      {...register("budget", { required: true })}
                    />
                    <Radio
                      label="$100K - $500K"
                      {...register("budget", { required: true })}
                    />
                    <Radio
                      label="$500K+"
                      {...register("budget", { required: true })}
                    />
                  </div>
                </fieldset>
                <div className="sm:col-span-2">
                  <Input
                    label="How did you hear about us?"
                    {...register("hearAbout")}
                    error={errors.hearAbout}
                  />
                </div>
                {error && (
                  <div className="rounded bg-red-100 px-4 py-2 text-red-800 sm:col-span-2">
                    {error}
                  </div>
                )}
                <div className="text-right sm:col-span-2">
                  <button
                    type="submit"
                    className="inline-flex justify-center rounded-md border border-transparent bg-primary-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Content>
      <Footer />
    </>
  );
}

export default ContactPage;

export const Head = () => <SEO title="Contact Us" />;
